import { graphql } from 'gatsby'
import React from 'react'
import Banner from 'components/Banner'
import Body from 'components/Body'
import PostList from 'components/PostList'
import Footer from 'components/Footer'
import FooterUpper from 'components/FooterUpper'
import { useLocalization } from 'gatsby-theme-i18n'
import SEO from 'components/SEO'

const Page = ({ data }) => {
  const { locale } = useLocalization()
  const trainings = data[`trainings_${locale}`].nodes
  const page = data[`page_${locale}`]

  const titleContent = {
    en: {
      title: 'Trainings and Workshops',
      excerpt:
        'Feldenkrais Accredited Training and additional course offerings',
    },
    zh: {
      title: '培训及工作坊',
      excerpt: '菲登奎斯认证培训和其他课程',
    },
  }

  return (
    <div className='page' id='page-about-feldenkrais'>
      <div>
        <SEO page={page} />
        <Banner content={page.banner} />
        <Body body={page.body} />
        <PostList
          titleContent={titleContent[locale]}
          content={trainings}
          category='training'
          showListBg={true}
          showButtonLinks={true}
          showViewAll={true}
        />
        <FooterUpper hasBg={true} />
        <Footer />
      </div>
    </div>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    page_en: contentfulPage(
      slug: { eq: "home" }
      node_locale: { eq: "en-US" }
    ) {
      title
      seo {
        title
        description
      }
      banner {
        pretitle
        title
        subtitle
      }
      body {
        ... on ContentfulBlockIntro {
          __typename
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockIntroExtended {
          __typename
          imageText1 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          imageText2 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          image {
            image {
              ...GatsbyImage
            }
            caption
          }
        }
        ... on ContentfulBlockCenterText {
          __typename
          id
          title
          body {
            raw
          }
          isLeftAligned
        }
        ... on ContentfulBlockImageText {
          __typename
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockContact {
          __typename
          phone
          email
          address {
            raw
          }
          googleMapLink
          baiduMapLink
          image {
            ...GatsbyImage
          }
        }
      }
    }
    page_zh: contentfulPage(
      slug: { eq: "home" }
      node_locale: { eq: "zh-CN" }
    ) {
      title
      seo {
        title
        description
      }
      banner {
        pretitle
        title
        subtitle
      }
      body {
        ... on ContentfulBlockIntro {
          __typename
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockIntroExtended {
          __typename
          imageText1 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          imageText2 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          image {
            image {
              ...GatsbyImage
            }
            caption
          }
        }
        ... on ContentfulBlockCenterText {
          __typename
          id
          title
          body {
            raw
          }
          isLeftAligned
        }
        ... on ContentfulBlockImageText {
          __typename
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockContact {
          __typename
          phone
          email
          address {
            raw
          }
          googleMapLink
          baiduMapLink
          image {
            ...GatsbyImage
          }
        }
      }
    }
    trainings_en: allContentfulTraining(
      filter: { node_locale: { eq: "en-US" } }
      sort: { fields: order, order: ASC }
      limit: 3
    ) {
      nodes {
        slug
        title
        excerpt
        dates
        isFeatured
        imageThumb {
          ...GatsbyImage
        }
      }
    }
    trainings_zh: allContentfulTraining(
      filter: { node_locale: { eq: "zh-CN" } }
      sort: { fields: order, order: ASC }
      limit: 3
    ) {
      nodes {
        slug
        title
        excerpt
        dates
        isFeatured
        imageThumb {
          ...GatsbyImage
        }
      }
    }
  }
`
